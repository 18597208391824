import "./App.css";
import { Switch, Route, Redirect } from "react-router-dom";
import React from "react";
// import Home from "./pages/Home/Home";
import Contact from "./pages/Contact/Contact";
import Resume from "./pages/Resume/Resume";
import ReactGa from "react-ga";
import { useEffect } from "react";
import Work from "./pages/Work/Work";
import Home from "./pages/Home/Home";
import { Helmet } from "react-helmet";

function App() {
  useEffect(() => {
    ReactGa.initialize(process.env.GA_CODE);

    //to report page view
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <Helmet>
        <html lang='en' />
        <title>Arash Arora</title>
        <meta name='description' content="Hi, I'm Arash Arora" />
      </Helmet>
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/contact' component={Contact} />
        <Route path='/profile' component={Resume}></Route>
        <Route path='/work' component={Work}></Route>
        {/* <Route path='/journal' component={Journal}></Route> */}

        <Redirect to='/' />
      </Switch>
    </>
  );
}

export default App;
