const RoadData = [
  {
    date: "2004 - 2017",
    head: "High School",
    subtitle: "CGPA - 9.2",
  },
  {
    date: "2017 - 2019",
    head: "Senior Secondary School",
    subtitle: "93.5%",
  },
  {
    date: "2019 - Present",
    head: "Btech CSE with Cybersecurity & Forensics",
    subtitle: "MRIIRS",
  },
  {
    date: "Nov' 2020 - Feb' 2021",
    head: "Web Development Internship",
    subtitle: "Aprobacian",
  },
  {
    date: "May' 2021 - July' 2021",
    head: "Web Development Internship",
    subtitle: "Parallax India",
  },
  {
    date: "May' 2021 - June' 2021",
    head: "Mern Stack Developer",
    subtitle: "Froshlink",
  },
  {
    date: "May' 2021 - July' 2021",
    head: "PHP developer",
    subtitle: "Acuevers",
  },
  {
    date: "July' 2021 - Aug' 2021",
    head: "Cybersecurity Internship",
    subtitle: "Cisco",
  },
  {
    date: "Oct' 2021 - Present",
    head: "Penetration Tester",
    subtitle: "Virtually Testing Foundation",
  },
];

export default RoadData;
