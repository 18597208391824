import React from "react";
import "./Timeline.css";
import Road from "./Road";

function Timeline() {
  return (
    <div className='timeline-section'>
      <div className='timeline-heading'>
        <h1>Roadmap</h1>
      </div>
      <div className='timeline-items'>
        <Road />
      </div>
    </div>
  );
}

export default Timeline;
