import React, { useState, useLayoutEffect, useEffect } from "react";
import "./Portfolio.css";
import PortfolioList from "./PortfolioList";
import {
  featured,
  nodeProjects,
  pythonProjects,
  reactProjects,
  websiteData,
} from "./Projects-data";
import { BsCaretDown } from "react-icons/bs";
function Projects() {
  const [selected, setSelected] = useState(1);
  const [dataToBeFetched, setDataToBeFetched] = useState(featured);

  const list = [
    {
      id: 1,
      name: "Featured",
    },
    {
      id: 2,
      name: "React/NextJS",
    },
    {
      id: 3,
      name: "Python",
    },
    {
      id: 4,
      name: "NodeJS",
    },
    {
      id: 5,
      name: "Website",
    },
  ];

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }
  const [width] = useWindowSize();
  const [position, setPosition] = useState("static");
  setTimeout(() => setPosition("fixed"), 3000);

  useEffect(() => {
    switch (selected) {
      case 1:
        setDataToBeFetched(featured);
        break;
      case 2:
        setDataToBeFetched(reactProjects);
        break;
      case 3:
        setDataToBeFetched(pythonProjects);
        break;
      case 4:
        setDataToBeFetched(nodeProjects);
        break;
      case 5:
        setDataToBeFetched(websiteData);
        break;
      default:
        setDataToBeFetched(featured);
    }
  }, [selected]);
  let result = "";
  return (
    <div className='portfolio' id='portfolio'>
      <h1>Projects</h1>

      <div
        className='projectsList'
        id='projectsList'
        style={{ position: width <= 768 ? position : "static" }}>
        {list.map((item) => (
          <PortfolioList
            key={item.name}
            name={item.name}
            active={selected === item.id}
            setSelected={setSelected}
            id={item.id}
          />
        ))}
      </div>
      <div className='sm-projectsList'>
        <div className='drop-box'>
          <BsCaretDown className='down-icon' />
          <select
            name=''
            id=''
            onChange={(e) => {
              result = list.filter((item) => item.name === e.target.value);
              setSelected(result[0].id);
              console.log(e.target.value);
            }}>
            {list.map((item) => {
              return (
                <option key={item.name} value={item.name}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className='portfolio-container'>
        {dataToBeFetched.map((item) => {
          return (
            <a href={item.link}>
              <div className='portfolio-item' key={item.id}>
                <img loop='infinite' src={item.img} alt={item.alt} />
                <h3>{item.title}</h3>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
}

export default Projects;
