import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

let preloader = document.getElementById("loading");

setTimeout(function myFunction() {
  preloader.style.display = "none";
  document.getElementsByTagName("body")[0].style.overflow = "auto";
}, 3000);
document.getElementsByTagName("body")[0].style.overflow = "hidden";

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// let preloader = document.getElementById("loading");
// setTimeout(function myFunction(){
//   preloader.style.display="none";
//   document.getElementsByTagName("body")[0].style.overflow="auto";
// }, 3000);
// document.getElementsByTagName("body")[0].style.overflow="hidden";
