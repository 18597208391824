import React from "react";

function PortfolioList({ name, active, setSelected, id }) {
  return (
    <div id='portfolio-list'>
      <h4 onClick={() => setSelected(id)} className={active ? "active" : ""}>
        {name}
      </h4>
    </div>
  );
}

export default PortfolioList;
