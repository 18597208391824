import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import Projects from "../../components/projects/Projects";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";

function Work() {
  const [active, setActive] = useState(false);
  return (
    <div
      className='main-container'
      id='work-main'
      style={{
        overflowY: active ? "hidden" : "",
        height: active ? "100vh" : "",
        background: "#eee",
      }}>
      <Navbar active={active} setActive={setActive} />
      <Helmet>
        <title>Arash Arora - Work</title>
        <meta
          name='description'
          content="Hey!, I'm Arash Arora, this is my work.."
        />
        <meta property='og:site_name' content='arasharora.com' />
        <meta property='og:site_name' content='Work - Arash Arora' />
        <meta
          name='keywords'
          content='Arash, Arash Arora, Arash Portfolio, Arash Manav Rachna, Arash Arora work, Arora Arash'
        />
      </Helmet>
      <Projects />
      <Footer />
    </div>
  );
}

export default Work;
