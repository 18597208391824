import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import Video from "../../videos/video.mp4";
import Social from "../Social";
const imgUrl = require("../Navbar/logoo2.png").default;

const Footer = () => {
  var current_year = new Date();
  current_year = current_year.getFullYear();
  return (
    <footer>
      <div className='left-container'>
        <div className='video'>
          <video autoPlay loop muted src={Video} type='video/mp4' />
        </div>
      </div>
      <div className='right-container'>
        <div className='web-detail-container1'>
          <div className='footer-logo'>
            <Link to='/'>
              <img
                src={imgUrl}
                alt=''
                onClick={() => window.location.reload(false)}
              />
            </Link>
          </div>
          <Social />
        </div>

        <div className='web-detail-container1 wd-container2'>
          <div className='about'>
            <ul>
              <li>
                <a href='https://arasharora2001.medium.com'>Blogs</a>
              </li>
              <li>
                <Link to='/work'>Work</Link>
              </li>
              <li>
                <Link to='/myProfile'>My Profile</Link>
              </li>
              <li>
                <Link target='_blank' to='/contact'>
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className='description'>
            <p className='gallery'>
              Visit My <span>Gallery</span>
            </p>
            <p className='blog'>
              Visit my latest blog: <br />
              <a href='https://arasharora2001.medium.com/the-five-stages-of-ethical-hacking-46dfb457d048'>
                <span>&#8221; The Five Stages Of Ethical Hacking &#8221;</span>
              </a>
            </p>
          </div>
        </div>
        <hr />
        <div className='copyright'>
          <p>Copyright © {current_year} Arash Arora</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
