export const featured = [
  {
    id: 0,
    img: "https://lh3.googleusercontent.com/Lshi9_7th8EFOIkmKe3OOf5DNgIwhQljTQdP5ecBphT5MSdTQHalaqa43spM9vuQOzr-QqGhrP4HgmVDv7RdbYcqTYMAR_tdR4aRz74uoB-DA182njyhlEoeqL2uuKm_JmrMCMt9mHY=w2400?source=screenshot.guru",
    alt: "Weather App",
    link: "https://arash-weatherapp.herokuapp.com/",
    title: "Weather App",
  },
  {
    id: 1,
    img: "https://lh3.googleusercontent.com/Xj1gMjrFZDG77ThYUOsYlG-tJeJe0-1uo06M9p0ImiaUVZ7_EYQTJFoMptPUYD9p639KCQYZK8z1Rjoc6CZxrGt1yvNSwL7ZL7bvDtP3GPcyQYRs2C-ntdy80wy3dJf9cIR7Q2MLtBI=w2400?source=screenshot.guru",
    alt: "React Portfolio",
    link: "https://arasharora.com",
    // imgS: "https://lh3.googleusercontent.com/IfhLIT_53zKoiUe9dW6kfBRzMgCG6VyFADWqM21LMsIh8KhBZdFPLSA1iqqe8-ZtkvvmhatwHY1qJka9OyV2YqC-NUHCF-PLNOisuSLhy4MkhP-vGZ_JWlZdFvxXCWLIvuBVtgqJPuQ=w600-h287-p-k",
    title: "ReactJS Portfolio",
  },
  {
    id: 2,
    img: "https://lh3.googleusercontent.com/ptWeQw45hHCxQGNU4m3xNXEUvvdHJM4nkgTsjzNckpFxSCT7R8L_GKsIGdZPEOFGmxd5ZI99I4eqGpaBzGQ7Y4ske06MekcR9albTsOdcv0RyOGCr-E_DcnSqEIr-kPVIZUH8Y2vs_A=w2400?source=screenshot.guru",
    alt: "Hulu Clone",
    link: "https://arash-hulu.vercel.app/",
    title: "NextJS Hulu Clone",
  },
  {
    id: 3,
    img: "https://lh3.googleusercontent.com/GgWCJOMGIgCDmHzhtY40OVU_OMXuoZqU3cYq6_8kH2S_nz-E1pV6HSFCWQMc8XYE8DzNdHDhUi0N3rHyiDgOzPAYLUXg2BA3mK_3GfiPzbCekv7Imdk2IPVrvEkRpqz-n0HZcQCdSk8=w2400?source=screenshot.guru",
    alt: "Airbnb Clone",
    link: "https://arash-airbnb-demo.vercel.app/",
    title: "NextJS Airbnb Clone",
  },
  {
    id: 4,
    img: "https://lh3.googleusercontent.com/e50NxP8kIGdfCqA02r62nHfZkeYe21UnFG97yY9KGs1x2WYF0Dang8VEwR_xCCHARKWO3unZFgg3bebzZpo0YUwtfMIFm6z0qq4V5ejf572NQ2Et2JXOI0_BJ47hSid1NTAzfTmpNEs=w2400?source=screenshot.guru",
    alt: "ReactJS Portfolio 2",
    link: "https://friendly-kare-903a27.netlify.app/",
    title: "ReactJS Portfolio 2",
  },
  {
    id: 5,
    img: "https://lh3.googleusercontent.com/t2888BdysdHRQ5Qebu0YXQz9CXdek5N60-QR6Wd6wT4r8VXDsn_NGaPVVEJCk2SA3iuoTOx2PIfyvQUuzlwcKpgXbZhn7Dj98CJnFAbCTyVvdoKNTzQPXKPmPQ9-f-fLl2n9JMwTd5E=w2400?source=screenshot.guru",
    alt: "SeleniumNavigateThroughWebsites",
    link: "https://github.com/arash-arora/Selenium",
    title: "Selenium Navigate through Website",
  },
  {
    id: 6,
    img: "https://lh3.googleusercontent.com/QBFUOXvvDa1jBQBN7eD35XPXE-7_R21eRp6r_-X_63EveoLrntv9s5yJX51YuD2SpoZEAq-GB6HqXFQGHNlSLT9x7sZY2-xYRFnlmdWmFGeuE-VY4QFlmZacH-is7MaP22YFkEIqpkc=w2400?source=screenshot.guru%22%3E%20%3Cimg%20src=%22https://lh3.googleusercontent.com/QBFUOXvvDa1jBQBN7eD35XPXE-7_R21eRp6r_-X_63EveoLrntv9s5yJX51YuD2SpoZEAq-GB6HqXFQGHNlSLT9x7sZY2-xYRFnlmdWmFGeuE-VY4QFlmZacH-is7MaP22YFkEIqpkc=w600-h278-p-k",
    alt: "Keeper App",
    link: "https://bh1ck.csb.app/",
    title: "ReactJS Keeper App",
  },
  {
    id: 7,
    img: "https://lh3.googleusercontent.com/aTd8umeRS4RcOjDXsMtP6IIGQk7TW6EAuXE2a_YgUC0XQVdJC8Z25t-PnMeJDcrtTCt30xhuBVMNQ4xgblqS6Rsx8cUw9U5yYObK_5UzRs--YOySWHqMF7BFVA9BwUisr5j8Bc9ChH8=w2400?source=screenshot.guru",
    alt: "lorem",
    link: "https://arash-arora.github.io/celebrity/",
    title: "ReactJS Celebrity Website",
  },
  {
    id: 8,
    img: "https://lh3.googleusercontent.com/V70smvHL4QXNPMvhBdOsZE53Mjxm0C2HOf0RHmEdfUfhXI2p0WKxiY_KUkdLRFfhO7i4zIEBhyOHJug6D9V6ftGFdJooXYVPojW22CJ_NTiZVVSLDLxHa9M71g24TQhaqHG9uSoHPWI=w2400?source=screenshot.guru",
    alt: "HTML CSS Portfolio-2",
    link: "http://arasharora.rf.gd/?i=1",
    title: "HTML-CSS Portfolio-2",
  },
  {
    id: 9,
    img: "https://lh3.googleusercontent.com/tPQELjnJc_KKt6r5GrThFkYbZazHwsTv-iyXVsyWPwe7oC5_boBhtNsrr6b7Xon3X3HcW6zG3UWmaqGTyFWRwd7RR9w2eo2UpVhSJYkLTGJJvkJRHqnPNTsLjWQopyeqoKfyfAR9LNo=w2400?source=screenshot.guru",
    alt: "HTML CSS Portfolio-1",
    link: "https://arash-arora.github.io/Portfolio/",
    title: "HTML-CSS Portfolio-1",
  },
  {
    id: 10,
    img: "https://lh3.googleusercontent.com/fe5s8rGBwP3V3oiLnAEFrxiLldbnyhYeq9YdSe7EM3k0XqPF_gk6ZNlxBvdGr69bRlWdB0ttmAOpdrosySzdo6NN20ARSqouOlgcxIXrA8Ry679KnoxAAd5Sn-oWi8uHlynCJTHsCvw=w2400?source=screenshot.guru%22%3E%20%3Cimg%20src=%22https://lh3.googleusercontent.com/fe5s8rGBwP3V3oiLnAEFrxiLldbnyhYeq9YdSe7EM3k0XqPF_gk6ZNlxBvdGr69bRlWdB0ttmAOpdrosySzdo6NN20ARSqouOlgcxIXrA8Ry679KnoxAAd5Sn-oWi8uHlynCJTHsCvw=w600-h315-p-k",
    alt: "RSA",
    link: "https://github.com/arash-arora/RSA",
    title: "RSA encryption algorithm",
  },
];

export const reactProjects = [
  {
    id: 1,
    img: "https://lh3.googleusercontent.com/Xj1gMjrFZDG77ThYUOsYlG-tJeJe0-1uo06M9p0ImiaUVZ7_EYQTJFoMptPUYD9p639KCQYZK8z1Rjoc6CZxrGt1yvNSwL7ZL7bvDtP3GPcyQYRs2C-ntdy80wy3dJf9cIR7Q2MLtBI=w2400?source=screenshot.guru",
    alt: "React Portfolio",
    link: "https://arasharora.com",
    // imgS: "https://lh3.googleusercontent.com/IfhLIT_53zKoiUe9dW6kfBRzMgCG6VyFADWqM21LMsIh8KhBZdFPLSA1iqqe8-ZtkvvmhatwHY1qJka9OyV2YqC-NUHCF-PLNOisuSLhy4MkhP-vGZ_JWlZdFvxXCWLIvuBVtgqJPuQ=w600-h287-p-k",
    title: "ReactJS Portfolio",
  },
  {
    id: 2,
    img: "https://lh3.googleusercontent.com/ptWeQw45hHCxQGNU4m3xNXEUvvdHJM4nkgTsjzNckpFxSCT7R8L_GKsIGdZPEOFGmxd5ZI99I4eqGpaBzGQ7Y4ske06MekcR9albTsOdcv0RyOGCr-E_DcnSqEIr-kPVIZUH8Y2vs_A=w2400?source=screenshot.guru",
    link: "https://arash-hulu.vercel.app/",
    alt: "Hulu Clone",
    title: "NextJS Hulu Clone",
  },
  {
    id: 3,
    img: "https://lh3.googleusercontent.com/GgWCJOMGIgCDmHzhtY40OVU_OMXuoZqU3cYq6_8kH2S_nz-E1pV6HSFCWQMc8XYE8DzNdHDhUi0N3rHyiDgOzPAYLUXg2BA3mK_3GfiPzbCekv7Imdk2IPVrvEkRpqz-n0HZcQCdSk8=w2400?source=screenshot.guru",
    link: "https://arash-airbnb-demo.vercel.app/",
    alt: "Airbnb Clone",
    title: "NextJS Airbnb Clone",
  },
  {
    id: 4,
    img: "https://lh3.googleusercontent.com/e50NxP8kIGdfCqA02r62nHfZkeYe21UnFG97yY9KGs1x2WYF0Dang8VEwR_xCCHARKWO3unZFgg3bebzZpo0YUwtfMIFm6z0qq4V5ejf572NQ2Et2JXOI0_BJ47hSid1NTAzfTmpNEs=w2400?source=screenshot.guru",
    alt: "ReactJS Portfolio 2",
    link: "https://friendly-kare-903a27.netlify.app/",
    title: "ReactJS Portfolio 2",
  },
  {
    id: 5,
    img: "https://lh3.googleusercontent.com/VG6Jip74r9p5dFvJqpcx3Dh5oKX4kU9-VCOrzWw7ijSkwtgTbc4TXXebEdNF0CUJ4rfXeKMisTV9pJUBB8PjJOh1F7ywLImcdnMhnzImbzKnDkFYznsEv8idKBejI4is0-XaIBSew1o=w2400?source=screenshot.guru",
    alt: "Todo App",
    link: "https://8petx.csb.app/",
    title: "ReactJS Todo App",
  },
  {
    id: 6,
    img: "https://lh3.googleusercontent.com/QBFUOXvvDa1jBQBN7eD35XPXE-7_R21eRp6r_-X_63EveoLrntv9s5yJX51YuD2SpoZEAq-GB6HqXFQGHNlSLT9x7sZY2-xYRFnlmdWmFGeuE-VY4QFlmZacH-is7MaP22YFkEIqpkc=w2400?source=screenshot.guru%22%3E%20%3Cimg%20src=%22https://lh3.googleusercontent.com/QBFUOXvvDa1jBQBN7eD35XPXE-7_R21eRp6r_-X_63EveoLrntv9s5yJX51YuD2SpoZEAq-GB6HqXFQGHNlSLT9x7sZY2-xYRFnlmdWmFGeuE-VY4QFlmZacH-is7MaP22YFkEIqpkc=w600-h278-p-k",
    alt: "Keeper App",
    link: "https://bh1ck.csb.app/",
    title: "ReactJS Keeper App",
  },
  {
    id: 7,
    img: "https://lh3.googleusercontent.com/xNpme0SfOy3D59u8S8QsUp9j_mh0RMBZFVfwsmJeyjzt5JBl7h-78LWBc-Z0WbYJArzxxjiF79_f523mDdTxT7SfHdgn-zHMhBqRUEzBjrb1Hky5f4ESPKmAIea75qdtbyDwY8tznzE=w2400?source=screenshot.guru",
    alt: "Monster React App",
    link: "https://arash-arora.github.io/monster-rolodex/",
    title: "Monster API Fetch with Search capbilities",
  },
];

export const websiteData = [
  {
    id: 1,
    img: "https://lh3.googleusercontent.com/V70smvHL4QXNPMvhBdOsZE53Mjxm0C2HOf0RHmEdfUfhXI2p0WKxiY_KUkdLRFfhO7i4zIEBhyOHJug6D9V6ftGFdJooXYVPojW22CJ_NTiZVVSLDLxHa9M71g24TQhaqHG9uSoHPWI=w2400?source=screenshot.guru",
    alt: "HTML CSS Portfolio-2",
    link: "http://arasharora.rf.gd/?i=1",
    title: "HTML-CSS Portfolio-2",
  },
  {
    id: 2,
    img: "https://lh3.googleusercontent.com/tPQELjnJc_KKt6r5GrThFkYbZazHwsTv-iyXVsyWPwe7oC5_boBhtNsrr6b7Xon3X3HcW6zG3UWmaqGTyFWRwd7RR9w2eo2UpVhSJYkLTGJJvkJRHqnPNTsLjWQopyeqoKfyfAR9LNo=w2400?source=screenshot.guru",
    alt: "HTML CSS Portfolio-1",
    link: "https://arash-arora.github.io/Portfolio/",
    title: "HTML-CSS Portfolio-1",
  },
  {
    id: 3,
    img: "https://lh3.googleusercontent.com/soqTRw5bAedtlcoEnGS3zQ_if-PLOs9b3mk9OQwDQvkiRxRqBovhZW9A9CGgBvsuOR-Xaj_MQSDIbklNw0pOjSD84-YGlk6wYr577QPAlijD8uvFuMOPW-kcyt8OQGD5WInXd3SSVZE=w2400?source=screenshot.guru",
    alt: "tindog",
    link: "https://arash-arora.github.io/tindog/",
    title: "tindog Website",
  },
  {
    id: 4,
    img: "https://lh3.googleusercontent.com/W9Ah9gB14HbdNQcAFHsIW2OuZZbzcGKcD5x8MxM9Dmv3yqGg7DiJ_PmfOgHWwIdt8M9exs4FJZhZkPYqMxwfS0CVrwVnQFDmSCDe4mJV73MS15biqegaRSMTmUHqKhNln0ssOibKunE=w2400?source=screenshot.guru",
    alt: "audioWebsite",
    link: "https://arash-arora.github.io/DrumKit/",
    title: "JS Website with embedded Audio",
  },
];

export const pythonProjects = [
  {
    id: 1,
    img: "https://lh3.googleusercontent.com/fe5s8rGBwP3V3oiLnAEFrxiLldbnyhYeq9YdSe7EM3k0XqPF_gk6ZNlxBvdGr69bRlWdB0ttmAOpdrosySzdo6NN20ARSqouOlgcxIXrA8Ry679KnoxAAd5Sn-oWi8uHlynCJTHsCvw=w2400?source=screenshot.guru%22%3E%20%3Cimg%20src=%22https://lh3.googleusercontent.com/fe5s8rGBwP3V3oiLnAEFrxiLldbnyhYeq9YdSe7EM3k0XqPF_gk6ZNlxBvdGr69bRlWdB0ttmAOpdrosySzdo6NN20ARSqouOlgcxIXrA8Ry679KnoxAAd5Sn-oWi8uHlynCJTHsCvw=w600-h315-p-k",
    alt: "RSA",
    link: "https://github.com/arash-arora/RSA",
    title: "RSA encryption algorithm",
  },
  {
    id: 2,
    img: "https://lh3.googleusercontent.com/WLPSW5frUeGYRDxj46fDZAQw549c7tWYAgcXwwaPolb8oN1Jv7mJHq1c6iZwWw0rsrmxDtBtZlpsU6HvB1uF5bcYzQDT4-svBCkN_rK_fqUvqjlX2cWTEkTea0_A2WVsaR7dxsQT2_I=w2400",
    alt: "PortScanner",
    link: "https://github.com/arash-arora/port-scanner",
    title: "Port Scanner",
  },
  {
    id: 3,
    img: "https://lh3.googleusercontent.com/t2888BdysdHRQ5Qebu0YXQz9CXdek5N60-QR6Wd6wT4r8VXDsn_NGaPVVEJCk2SA3iuoTOx2PIfyvQUuzlwcKpgXbZhn7Dj98CJnFAbCTyVvdoKNTzQPXKPmPQ9-f-fLl2n9JMwTd5E=w2400?source=screenshot.guru",
    alt: "SeleniumNavigateThroughWebsites",
    link: "https://github.com/arash-arora/Selenium",
    title: "Selenium Navigate through Website",
  },
  {
    id: 4,
    img: "https://lh3.googleusercontent.com/MZI2OgO2nq6kYeQCpXuQ83pHiPOt3I0g0x-KcAYni63sPqTx9fOugfsXmD621GTSvZxmCfdO3dgADvN5o90PRvNbdAyg6ZYNCh79HnzI6DHPYVEwusX_9kviByKd9N531br3pQjKTts=w2400?source=screenshot.guru",
    alt: "PasswordGenerator",
    link: "https://github.com/arash-arora/Password-Generator",
    title: "Password Generator",
  },
  {
    id: 5,
    img: "https://lh3.googleusercontent.com/CV7C_5668Xteuhu49LZibkzbuqrIAs38HqnbYnXR_NegP-FVUaSLONrhHiDVNIs7az43bvmQYN7M7bVq31b3jDShl6kKl32zM_9PN3DTVmUDH4UUxVxhUNfGQraPow-T9EiufJdur_U=w2400?source=screenshot.guru",
    alt: "RockPaperScissor",
    link: "https://replit.com/@arasharora/Rock-Scissors-Paper#main.py",
    title: "Rock Paper Scissor",
  },
];

export const nodeProjects = [
  {
    id: 1,
    img: "https://lh3.googleusercontent.com/Lshi9_7th8EFOIkmKe3OOf5DNgIwhQljTQdP5ecBphT5MSdTQHalaqa43spM9vuQOzr-QqGhrP4HgmVDv7RdbYcqTYMAR_tdR4aRz74uoB-DA182njyhlEoeqL2uuKm_JmrMCMt9mHY=w2400?source=screenshot.guru",
    alt: "Weather App",
    link: "https://arash-weatherapp.herokuapp.com/",
    title: "Weather App",
  },
];
