export const homeObjOne = {
  id: "about",
  dataId: "1",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "STUDENT",
  headLine: "Arash Arora",
  description: `Hey! I am currently pursuing my Btech in CSE with CyberSecurity as a specialization from 
        Manav Rachna International Institute of Research and Studies. I’m a Cybersecurity enthusiast 
        who is fluent in Kali Linux and have basic ideas of the same. I’m Currently learning front-end web 
        development, and have developed some very basic websites. I’ve also worked with Arduino and created some projects.`,
  buttonLabel: "View My Projects",
  imgStart: true,
  img: require("../../images/img-1.png").default,
  alt: "Me",
  dark: true,
  primary: true,
  darkText: false,
  borderColor: false,
  paddingB: "270px",
};

export const homeObjTwo = {
  id: "resume",
  dataId: "2",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine:
    "“Live as if you were to die tomorrow. Learn as if you were to live forever.”",
  headLine: "...",
  description: `I am passionate about learning new skills and advancing myself. I have even completed several 
        training courses in Python, Linux, JavaScript, etc. Along with the courses, I’ve developed 
        some projects. In addition to the projects, I am currently learning computer security along with advanced web development`,
  buttonLabel: "Contact Me",
  imgStart: false,
  img: require("../../images/img-2.svg").default,
  alt: "Me",
  dark: false,
  primary: false,
  darkText: true,
  borderColor: true,
  paddingB: "120px",
};
