import React from "react";
import data from "./RoadData";

function Road() {
  return (
    <>
      {data.map((data) => {
        return (
          <div className='timeline-item'>
            <div className='timeline-dot' />
            <div className='timeline-date'>{data.date}</div>
            <div className='timeline-content'>
              <h3>{data.head}</h3>
              <p>{data.subtitle}</p>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default Road;
