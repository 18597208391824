import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";

export const Nav = styled.nav`
  ${
    "" /* background: ${({ scrollNav }) => (scrollNav ? "#000" : "transparent")};
  ${
    "" /*  */
  }
  background: #000;
  opacity: ${({ active }) => (active ? "1" : "0.7")};
  height: ${({ active }) => (active ? "100%" : "80px")};
  display: flex;
  margin-top: -80px;
  justify-content: center;
  z-index: 999999999999999999999 !important;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  transition: 0.6s ease-in-out;
`;

export const NavLogo = styled(LinkR)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  ${"" /* margin-left: 24px; */}
  width: 68px;
  font-weight: bold;
  text-decoration: none;
`;

// export const MobileIcon = styled.div`
//   display: none;

//   @media screen and (max-width: 992px) {
//     display: block;
//     position: absolute;
//     top: 0;
//     right: 0;
//     transform: translate(-100%, 60%);
//     font-size: 1.8rem;
//     cursor: pointer;
//     color: #fff;
//   }
// `;

// export const NavMenu = styled.ul`
//   display: flex;
//   align-items: center;
//   list-style: none;
//   text-align: center;
//   margin-right: -22px;

//   @media screen and (max-width: 992px) {
//     display: none;
//   }
// `;

// export const NavItem = styled.li`
//   height: 80px;
// `;

export const NavLinks = styled(LinkS)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  font-size: 22px;
  &.active {
    border-bottom: 3px solid #01bf75;
  }
`;

// export const NavBtn = styled.nav`
//   display: flex;
//   align-items: center;

//   @media screen and (max-width: 992px) {
//     display: none;
//   }
// `;

// export const NavBtnLink = styled(LinkR)`
//   border-radius: 10px;
//   background: #fff;
//   white-space: nowrap;
//   padding: 10px 22px;
//   color: #010606;
//   font-size: 18px;
//   outline: none;
//   border: none;
//   cursor: pointer;
//   transition: all 0.2s ease-in-out;
//   text-decoration: none;

//   &:hover {
//     transition: all 0.2s ease-in-out;
//     background: #010606;
//     color: #fff;
//     border: 2px solid #fff;
//   }
// `;

export const Logo = styled.img`
  max-width: 70px;
  width: 100%;
`;
