import React, { useState, lazy, Suspense } from "react";
import Navbar from "../../components/Navbar";
import { homeObjOne, homeObjTwo } from "../../components/InfoSection/Data";
import HeroSection from "../../components/HeroSection";
import "./Home.css";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";
import Timeline from "../../components/Timeline/Timeline";
const InfoSection = lazy(() => import("../../components/InfoSection"));
const Services = lazy(() => import("../../components/Services"));
const Slider = lazy(() => import("../../components/Slider"));
const Badges = lazy(() => import("../../components/Badges"));

const Home = () => {
  const [active, setActive] = useState(false);
  return (
    <>
      <Helmet>
        <title>Arash Arora</title>
        <meta name='description' content="Hey!, I'm Arash Arora.." />
        <meta property='og:site_name' content='arasharora.com' />
        <meta property='og:site_name' content='Home - Arash Arora' />
        <meta
          name='keywords'
          content='Arash, Arash Arora, Arash Portfolio, Arash Manav Rachna, Arora Arash'
        />
      </Helmet>
      <div
        className='main-container'
        style={{
          overflowY: active ? "hidden" : "",
          height: active ? "100vh" : "",
        }}>
        <Navbar active={active} setActive={setActive} />
        <HeroSection />
        <Suspense fallback={<h1>Still Loading…</h1>}>
          <InfoSection {...homeObjOne} />
          <InfoSection {...homeObjTwo} />
          <Services />
          <Badges />
          <Slider />
          <Timeline />
        </Suspense>
        <Footer />
      </div>
    </>
  );
};

export default Home;
