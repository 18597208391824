import React from "react";
import "./Navbar.css";
import LinkList from "./LinkList";
import img from "../../images/bg3.JPG";

import { NavLogo, Logo, Nav } from "./NavbarElements.js";
const imgUrl = require("./logoo2.png").default;

function Navbar({ active, setActive }) {
  function handleClick() {
    setActive(!active);
  }

  return (
    <Nav active={active}>
      <div
        className={`container ${active ? "active" : ""}`}
        style={{ height: active ? "100%" : "" }}>
        <div className='navbar'>
          <div className='menu'>
            <NavLogo to='/'>
              <Logo src={imgUrl} alt='logo' />
            </NavLogo>
            <div className='hamburger-menu' onClick={handleClick}>
              <div className='bar'></div>
            </div>
          </div>
        </div>
        <div className='site-header-overlay'>
          <div className='overlay-bg'></div>
          <div className='overlay-wrapper'>
            <div className='menufull'>
              <div className='menu-1stcol'>
                <div className='menu-links'>
                  <LinkList />
                </div>
              </div>
              <div className='menu-2ndcol'>
                <div className='menufull-media'>
                  <img src={img} alt='' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Nav>
  );
}

export default Navbar;
