import React, { useEffect, useRef } from "react";
import { init } from "ityped";
import "./Hero.css";

import { HeroContainer, HeroBg, HeroContent, HeroP } from "./HeroElements";
import Fade from "react-reveal/Fade";

const HeroSection = () => {
  const textRef = useRef();
  useEffect(() => {
    init(textRef.current, {
      showCursor: false,
      startDelay: 1350,
      backDelay: 1500,
      backSpeed: 70,
      strings: ["Student!", "Web Developer!", "Security Geek!"],
    });
  }, []);
  return (
    <div id='hero'>
      <HeroContainer>
        <HeroBg />
        <Fade bottom>
          <HeroContent>
            <h1 className='my-name' style={{ color: "white" }}>
              Arash Arora
            </h1>

            <div className='qualification'>
              <HeroP ref={textRef}></HeroP>
            </div>
          </HeroContent>
        </Fade>
      </HeroContainer>
    </div>
  );
};

export default HeroSection;
